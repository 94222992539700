import React, { useEffect, useState } from "react";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import toString from "lodash/toString";
import Loading from "./Loading";

const Weather: React.SFC = () => {
  const [loading, setLoading] = useState(false);
  const [weather, setWeather] = useState<any>({});

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://asia-northeast1-takasu.cloudfunctions.net/weather")
      .then((resp) => {
        setWeather(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const formatTemp = (temp: number): string => {
    const parts = toString(temp).split(".");
    return parts[0];
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Current Weather in Takasu</h4>
        {loading && <Loading />}
        {!loading && !isEmpty(weather) && (
          <div className="d-flex flex-row align-items-center pt-1">
            <img
              src={`https://openweathermap.org/img/wn/${weather.icon}@2x.png`}
              alt={weather.description.en}
              className="img-fluid"
            />
            <span className="mr-3" style={{ fontSize: "3rem" }}>
              {formatTemp(weather.temp)}°
            </span>
            <div>
              <h3>{weather.description.en}</h3>
              <h5 className="font-jp" style={{ fontWeight: 400 }}>
                {weather.description.ja}
              </h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Weather;
