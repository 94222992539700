import React from "react";

const AboutTakasu: React.FC = () => (
  <div className="card">
    <div className="card-body">
      <h4 className="card-title">About Takasu ♥️</h4>
      <p className="card-text">
        <a
          href="https://www.town.takasu.hokkaido.jp/sangyou/syoukougyou/kaigyo_shien.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Takasu (鷹栖町, Takasu-chō)
        </a>
        &nbsp;is a town in Kamikawa Subprefecture in{" "}
        <a href="https://en.wikipedia.org/wiki/Hokkaido" target="_blank" rel="noopener noreferrer">
          Hokkaido
        </a>
        ,&nbsp;Japan. Translated into English, Takasu means "hawk's nest". As of April 2020, the town has an estimated
        population of 6,780 people.
      </p>
    </div>
  </div>
);

export default React.memo(AboutTakasu);
