import React, { Fragment, useMemo } from "react";
import { NextSeo } from "next-seo";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import shortid from "shortid";
import firebase from "../utils/firebase";
import Link from "../components/Link";
import Image from "../components/Image";
import AboutTakasu from "../components/AboutTakasu";
import Weather from "../components/Weather";
import Categories from "../components/Categories";
import withAuthUser from "../utils/pageWrappers/withAuthUser";
import withAuthUserInfo from "../utils/pageWrappers/withAuthUserInfo";

const Home = (props) => {
  const getDataColumns = (articles) => {
    const columns = [[], [], []];
    let column = 0;
    articles.forEach((article) => {
      columns[column].push(article);
      if (column >= columns.length - 1) {
        column = 0;
      } else {
        column++;
      }
    });
    return columns;
  };

  const articles = useMemo(() => getDataColumns(props.articles), [props.articles]);

  return (
    <Fragment>
      <NextSeo
        title="Best place in Hokkaido"
        description="北海道鷹栖町から田舎生活の情報をお届け!, We are trying to be the web's #1 place for anything related to Takasu City in Asahikawa, Hokkaido."
        openGraph={{
          url: "https://www.takasucity.com/",
          title: "鷹栖町 - Takasu City Hokkaido",
        }}
        additionalMetaTags={[
          {
            property: "yandex-verification",
            content: "0ca7598c61d3b894",
          },
        ]}
      />
      <div className="container-fluid">
        <div className="row">
          {isEmpty(props.articles) && (
            <div className="col-md-9">
              <div className="d-flex flex-row align-items-center justify-content-center mt-4">
                <h4 className="text-center">Sorry, something went wrong please try again later...</h4>
              </div>
            </div>
          )}
          {!isEmpty(props.articles) && (
            <div className="col-md-9">
              <div className="row">
                {articles.map((colum) => (
                  <div key={shortid.generate()} className="col-md-4">
                    {colum.map((article) => (
                      <article key={article.id} className="card mb-4">
                        <header className="card-header">
                          <div className="card-meta">
                            <time
                              className="timeago"
                              dateTime={moment.unix(article.publishedAt.seconds).format("YYYY-MM-DD HH:MM")}
                            >
                              {moment.unix(article.publishedAt.seconds).fromNow()}
                            </time>
                            &nbsp;in&nbsp;
                            <Link href={article.category.href}>
                              {article.category.name.ja}/{article.category.name.en}
                            </Link>
                          </div>
                          <Link href={`/article/${article.id}`}>
                            <h4 className="card-title font-jp">{article.title.ja}</h4>
                          </Link>
                        </header>
                        <Link href={`/article/${article.id}`}>
                          <Image uri={article.coverImage} className="card-img" alt={article.coverImageCaption} />
                        </Link>
                        <div className="card-body">
                          <p className="card-text font-jp">{article.description.ja}</p>
                        </div>
                      </article>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className="col-md-3 ml-auto">
            <aside className="sidebar">
              <AboutTakasu />
            </aside>
            <aside className="sidebar">
              <Weather />
            </aside>
            <aside className="sidebar sidebar-sticky">
              <Categories categories={props.categories} />
            </aside>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Home.getInitialProps = async (ctx) => {
  const articlesRef = await firebase
    .firestore()
    .collection("articles")
    .where("published", "==", true)
    .orderBy("publishedAt", "desc")
    .limit(10)
    .get();
  let articles = [];
  articlesRef.forEach((doc) => {
    articles.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  const categoriesRef = await firebase.firestore().collection("categories").get();
  let categories = [];
  categoriesRef.forEach((doc) => {
    categories.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return {
    articles,
    categories,
  };
};

// Use `withAuthUser` to get the authed user server-side, which
// disables static rendering.
// Use `withAuthUserInfo` to include the authed user as a prop
// to your component.
export default withAuthUser(withAuthUserInfo(Home));
