import React from "react";
import isEmpty from "lodash/isEmpty";
import Link from "./Link";

export interface CategoriesProps {
  categories?: {
    id: string;
    href: string;
    name: {
      en: string;
      jp: string;
    };
  }[];
}

const Categories: React.FC<CategoriesProps> = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Categories</h4>
        {isEmpty(props.categories) ? (
          <div className="d-flex flex-row align-items-center justify-content-center">
            <h5 className="text-center">No Categories</h5>
          </div>
        ) : (
          props.categories.map((category: any) => (
            <Link key={category.id} className="btn btn-light btn-sm mb-1 mr-1" href={category.href}>
              {category.name.ja}/{category.name.en}
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default Categories;
